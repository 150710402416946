<template>
  <b-card title="اتصل بنا ">
    <b-tabs>
     <b-tab active>
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/contact-us"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>اضافة وسيلة اتصال  </span>
          </a>
        </template>

       <AddContactUs  ></AddContactUs>
      </b-tab>
      <b-tab @click="$router.push('/all-contact-us')">
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/all-contact-us"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
           <!-- <a  href="/all-categories">All Categories</a> -->
           <span>كل وسائل الاتصال  </span>
           </a>
        </template>
        <router-view></router-view>
        <!-- <CategoriesList ref="allData"></CategoriesList> -->
      </b-tab>
     
     
     
    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'
import AddContactUs from './AddContactUs.vue';
import { ref, reactive } from "@vue/composition-api";


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,

    AddContactUs
},
   setup() {
    const allData=ref()
  
    const getData=()=>{
    
      // allData.value.getCategories()
           

    }
   return{
    getData,
    allData,
   

   }
   
   }
}
</script>
